<template>
  <el-dialog
    :visible.sync="scoreAlert"
    :width="isPhone ? '95%' : '50%'"
    class="scoreAlert"
  >
    <h4 slot="title" class="text-center text-success">
      {{ $t("toefl.balance.Unlock Now") }}
      <hr />
    </h4>
    <Balance />
    <div class="alert-body">
      <div class="balance-card" v-if="pointPackages.length > 0">
        <div>
          <div class="bb-intro-top">
            <i class="fas fa-donate"></i>
            <a class="b-icon-title">
              {{ $t("toefl.balance.Choose your service") }}
            </a>
          </div>
          <div class="b-info">
            <div class="tip">
              <div class="text">
                <router-link :to="{ name: 'Gradingsamples' }" target="_blank">
                  <i class="el-icon-star-on"></i>
                  {{ $t("toefl.balance.exp") }}
                </router-link>
                <div v-if="packageActive.title !== ''">
                  <b>{{ packageActive.title }}</b>
                </div>
              </div>
            </div>
            <div class="trade-list-wrapper row">
              <div
                class="col-sm-4"
                v-for="(trade, index) in pointPackages"
                :key="index"
                v-show="showCard(trade.category)"
              >
                <div
                  :class="
                    trade.selected ? 'trade-wrapper selected' : 'trade-wrapper'
                  "
                  @click="selectPrice(index)"
                >
                  <div class="text-center">
                    <span class="text-success" style="font-size:32px">
                      {{ getPoint(trade) }}
                    </span>
                    <span class="bb-name">
                      {{ $t("toefl.T-Coins") }}
                    </span>
                    <div style="padding:0 10px;margin:10px 0">
                      {{ trade.title[langList[lang.value]] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="text-center">
              <el-button
                type="success"
                @click="payForPackage"
                v-if="packageActive.id > 0"
              >
                {{ $t("toefl.balance.Unlock Now") }}
              </el-button>
              <el-button v-else type="success" disabled>
                {{ $t("toefl.balance.Choose your service") }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Balance from "@/views/toefl/transcript/components/balance";
import TOEFL from "@/apis/toefl";

export default {
  components: { Balance },

  mixins: [],

  props: ["userExamId", "examInfo", "pointPackages"],
  computed: {
    ...mapState("user", ["lang"]),
    isPro() {
      return this.$store.getters["user/getIsPro"];
    }
  },
  data() {
    return {
      scoreAlert: false,
      packageActive: {
        id: 0,
        title: ""
      },
      packageContent: {
        "1": this.$t("toefl.balance.Get scores for the entire test"),
        "2": "解鎖: 簡單評語",
        "3": "解鎖: 口說簡單評語 + 作文詳細評語",
        "4": this.$t("toefl.balance.Get scores for the test + simple comments"),
        "5": this.$t("toefl.balance.Get scores for the test + VIP comments")
      },
      isPhone: false,
      langList: {
        "EN-US": "en",
        "ZH-CN": "cn",
        "ZH-TW": "tw"
      }
    };
  },
  watch: {},

  mounted() {
    this.detectWidthIsPhone();
    window.addEventListener("resize", this.detectWidthIsPhone);
  },
  destroyed() {
    window.removeEventListener("resize", this.detectWidthIsPhone);
  },
  methods: {
    detectWidthIsPhone() {
      this.isPhone = document.body.clientWidth <= 1000;
    },
    async payForPackage() {
      if (this.packageActive.id > 0) {
        await TOEFL.payForPackage(this.userExamId, {
          point_package_id: this.packageActive.id
        });
      }
      this.$parent.getTranscript();
      this.scoreAlert = false;
    },
    showCard(category) {
      let canShow = false;
      if (this.examInfo.can_score == 1 && this.examInfo.can_suggest == 0) {
        if (category === "2" || category === "3") {
          canShow = true;
        } else {
          canShow = false;
        }
      }
      if (this.examInfo.can_score == 0 && this.examInfo.can_suggest == 0) {
        if (category === "2" || category === "3") {
          canShow = false;
        } else {
          canShow = true;
        }
      }
      return canShow;
    },
    selectPrice(index) {
      this.pointPackages.forEach(trade => {
        trade.selected = false;
      });
      this.pointPackages[index].selected = true;
      this.packageActive.id = this.pointPackages[index].id;
      // this.packageActive.title = this.packageContent[
      //   this.pointPackages[index].category
      // ];
      this.packageActive.title = this.pointPackages[index].title[this.langList[this.lang.value]];
    },
    getAlert() {
      this.scoreAlert = true;
    },
    getPoint(trade) {
      if (this.isPro) {
        return trade.point_pro;
      } else {
        return trade.point;
      }
    }
  }
};
</script>

<style scoped>
.scoreAlert >>> .el-dialog .el-dialog__header {
  padding-bottom: 0;
}
.scoreAlert >>> .el-dialog__body {
  padding: 10px 20px 20px 20px;
}
.left-body {
  width: 50%;
  height: 300px;
  padding: 20px;
}
.right-body {
  width: 50%;
  height: 300px;
  padding: 20px;
}
.info-tip {
  margin-top: 15px;
  margin-bottom: 13px;
  font-size: 14px;
  color: #99a2aa;
  letter-spacing: 0;
}
.tip {
  padding: 8px 16px;
  background-color: var(--themeColor) 40;
  border-radius: 4px;
  border-left: 5px solid var(--themeColor);
  margin: 0 0 20px 0;
}
.tip .text {
  font-size: 14px;
  font-weight: 500;
}
.balance {
  padding-top: 40px;
}
.balance-card {
  margin-bottom: 40px;
}
.bb-intro-top {
  width: 100%;
  border-top: 1px solid #ccd0d7;
  border-right: 1px solid #ccd0d7;
  border-left: 1px solid #ccd0d7;
  border-bottom: 1px solid #e2e9ed;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  background: #eef2f4;
  padding: 12px 28px;
  min-height: 46px;
}
.b-info {
  overflow: hidden;
  background: #fff;
  border-left: 1px solid #ccd0d7;
  border-right: 1px solid #ccd0d7;
  border-bottom: 1px solid #ccd0d7;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  padding: 20px 16px;
}
.b-info .info-tip {
  margin-top: 15px;
  margin-bottom: 13px;
  font-size: 14px;
  color: #99a2aa;
  letter-spacing: 0;
}
.b-info .balance-price {
  display: inline-block;
  font-size: 50px;
  color: var(--themeColor);
}
.b-info .balance-name {
  display: inline-block;
  font-size: 16px;
  margin-left: 4px;
  color: var(--themeColor);
}
.b-info .button {
  width: 140px;
  height: 45px;
  float: right;
  background: var(--themeColor);
  border-radius: 4px;
  font-size: 18px;
  line-height: 45px;
  text-align: center;
  color: #fff;
  letter-spacing: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.b-info .dividing-line {
  border-top: 1px solid #e7ecf1;
  margin-top: 16px;
  margin-bottom: 20px;
}
.trade-list-wrapper {
  margin-top: 20px;
}
.trade-wrapper {
  position: relative;
  width: 100%;
  height: 120px;
  margin-right: 16px;
  margin-bottom: 16px;
  border: 1px solid #e7ecf1;
  border-radius: 4px;
  font-size: 24px;
  color: #222;
  letter-spacing: 0;
  line-height: 24px;
  float: left;
  cursor: pointer;
}
.tags {
  position: absolute;
  left: -2px;
  top: -10px;
  height: 20px;
  padding: 0 5px;
  line-height: 18px;
  border-radius: 10px 0 10px 0;
}

.trade-wrapper > div {
  display: table;
  margin: 23px auto 0;
}
.trade-wrapper .bb-name {
  margin-left: 5px;
  font-size: 14px;
}
.trade-wrapper > div > div {
  margin-top: 8px;
  font-size: 14px;
  color: #757575;
  line-height: 14px;
}
.trade-wrapper.selected {
  border: 2px solid var(--themeColor);
  color: var(--themeColor);
  background: #effff6;
  box-shadow: 0 2px 12px 0 #effff6;
}
.trade-customn {
  width: 200px;
  margin-right: 0;
}
.trade-customn .tips {
  margin: 29px 0 0 18px;
  font-size: 14px;
  color: #999;
  letter-spacing: 0;
}
.line {
  color: #999999;
  text-decoration: line-through;
}
</style>
