<template>
  <div class="cover">
    <div
      :class="{
        'col-sm-12': showPassage,
        'col-sm-6': !showPassage,
        'cover-child': true,
        'passage-box': true
      }"
    >
      <div class="cover-left">
        <div v-if="questionTypeIsArticleAudio" class="passage">
          <div>
            <div class="resolve_content">
              <div class="title">
                {{ $t("pageTitle.Passage") }}
                <EditSpeaking
                  v-if="isAdmin"
                  type="passage"
                  :question_detail="question_detail"
                />
              </div>
            </div>
            <h6 class="article" v-html="question_detail.content.reading" />
          </div>
        </div>
        <div
          v-if="questionTypeIsArticleAudio || questionTypeIsAudio"
          class="passage"
        >
          <div>
            <div>
              <div class="resolve_content">
                <div class="title">
                  {{ $t("pageTitle.Audio") }}
                  <EditSpeaking
                    type="audio"
                    :question_detail="question_detail"
                  />
                </div>
              </div>
              <audio
                style="width:100%"
                :src="question_detail.content.talking_audio"
                controls
                controlsList="nodownload"
              ></audio>
              <h6>
                <div
                  class="article"
                  v-html="
                    replaceLine(question_detail.content.talking_audio_text)
                  "
                ></div>
              </h6>
            </div>
          </div>
        </div>
        <div
          :class="
            `passage ${
              !questionTypeIsArticleAudio && !questionTypeIsAudio ? 'expandHeight' : ''
            }`
          "
        >
          <div class="resolve_content">
            <div class="title">
              {{ $t("pageTitle.Question") }}
              <EditSpeaking
                v-if="isAdmin"
                type="question"
                :question_detail="question_detail"
              />
            </div>
            <h6 class="article">
              <div class="q_tags">
                <el-tag :key="tag.id" v-for="tag in question_detail.tags">
                  {{ tag.name[$store.getters["user/langShortValue"]] }}
                </el-tag>
              </div>
              {{ question_detail.question }}
            </h6>
          </div>
          <div id="passage"></div>
          <div class="open" @click="showPassage = !showPassage">
            <div class="text" v-show="!showPassage">
              <i class="fas fa-chevron-circle-right"></i>
            </div>
            <div class="text" v-show="showPassage">
              <i class="fas fa-chevron-circle-left"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!showPassage" class="col-sm-6 cover-child question-box">
      <div class="cover-left">
        <div class="question" v-if="!isBrowse">
          <div class="resolve_content">
            <div class="title">{{ $t("pageTitle.My Answer") }}</div>
            <div class="exp">
              <div v-if="hasAnswer">
                <audio
                  style="width:100%"
                  :src="question_detail.user_answers[0]"
                  controls
                  controlsList="nodownload"
                ></audio>
              </div>
              <div>
                <el-divider content-position="left">
                  Grades and Comments
                </el-divider>
              </div>
              <div style="margin-bottom:20px">
                <div v-if="isAdmin">
                  <div
                    v-if="examInfo.can_score === 1"
                    class="d-flex align-items-center"
                  >
                    <b>Response Score:&nbsp;</b>
                    <GradeLevelSelector
                      section="speaking"
                      :value="question_detail.score"
                      @change="score => (question_detail.score = score)"
                    />
                  </div>
                  <GradingSubScoreTable
                    v-if="examInfo.can_score === 1"
                    :value="(question_detail.answer_score_detail || {}).detail_score_comments"
                    :testCategory="TEST_CATEGORY"
                    :hideSubComment="!examInfo.can_suggest"
                    @change="
                      subScores =>
                        (question_detail.answer_score_detail = {
                          ...(question_detail.answer_score_detail || {}),
                          detail_score_comments: subScores
                        })
                    "
                  />
                </div>
                <div v-else>
                  <div v-if="examInfo.can_score === 1">
                    <b style="line-height:41px" v-if="examInfo.is_scored === 1">
                      Response Score:
                      <span class="score">
                        {{ `${question_detail.score}/4` }}</span
                      >
                    </b>
                    <div v-else>
                      <el-button
                        type="success"
                        style="width:100%"
                        disabled
                        plain
                        round
                      >
                        {{ $t("message.unlockScored") }}
                      </el-button>
                    </div>
                  </div>
                  <el-button v-else type="warning" @click="$parent.getAlert()">
                    {{ $t("toefl.balance.Show Score") }}
                  </el-button>
                  <SubScoreTable
                    :value="(question_detail.answer_score_detail || {}).detail_score_comments"
                    :testCategory="TEST_CATEGORY"
                    :canScore="examInfo.can_score"
                    :scored="examInfo.is_scored"
                    @unlock="$parent.getAlert()"
                  />
                </div>
              </div>
              <div>
                <div v-if="isAdmin">
                  <template v-if="examInfo.can_suggest === 1">
                    <div class="overall-comments">
                      <b>Overall Comments:&nbsp;</b>
                    </div>
                    <el-input
                      class="transcript"
                      type="textarea"
                      :rows="8"
                      placeholder="请输入内容"
                      v-model="question_detail.suggest"
                      :disabled="false"
                    >
                    </el-input>
                    <el-button
                      @click="saveTranscriptAndNotify"
                      size="mini"
                      type="success"
                    >
                      <i class="fa fa-save"></i> 保存並寄送通知
                    </el-button>
                  </template>
                </div>
                <div v-else>
                  <div style="margin-top:40px">
                    <div class="overall-comments">
                      <b>Overall Comments:&nbsp;</b>
                    </div>
                  </div>
                  <div v-if="examInfo.can_suggest === 1">
                    <div
                      v-if="examInfo.is_scored === 1"
                      style="word-break: normal !important;"
                    >
                      {{ question_detail.suggest }}
                    </div>
                    <div v-else>
                      <el-button type="success" disabled plain round>
                        {{ $t("message.pendingComments") }}
                      </el-button>
                    </div>
                  </div>
                  <el-button v-else type="warning" @click="$parent.getAlert()">
                    {{ $t("toefl.balance.Get Comments for This Prompt") }}
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <template
          v-if="question_detail.chapter_order === 2 && speakingQuestionLesson"
        >
          <div class="tips">
            <div class="adImageWrapper">
              <img class="adImage" :src="speakingQuestionLesson.cover" alt="" />
            </div>
            <h5>
              <a
                :href="`${baseDomainPath}lessons?v=${SPEAKING_QUESTION_LESSON_ID}`"
                target="_blank"
              >
                {{ $t("message.customLessonSpeak2AdTitle") }}
              </a>
            </h5>
          </div>
        </template> -->
        <div class="question">
          <div class="resolve_content">
            <div class="title">
              {{ $t("pageTitle.Sample Response") }}
              <EditSpeaking
                v-if="isAdmin"
                type="explanation"
                :question_detail="question_detail"
              />
            </div>
            <div class="response exp">
              <div
                v-if="lang === 'ZH-TW' || lang === 'EN-US'"
                v-html="traditionalized(replaceLine(question_detail.exp), lang)"
              ></div>
              <div v-else v-html="replaceLine(question_detail.exp)"></div>
            </div>
          </div>
        </div>
        <GoogleAd />
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import Translate from "@/mixins/translate.js";
import role from "@/mixins/role.js";
import TOEFL from "@/apis/toefl";
import lessonsApi from "@/apis/lessons";
import toefl from "@/mixins/toefl.js";
import EditSpeaking from "@/views/toefl/toeflResolve/Edit/Speaking";
import GradeLevelSelector from "@/views/toefl/grading/sections/GradeLevelSelector";
import GradingSubScoreTable from "@/views/toefl/grading/sections/SubScoreTable";
import SubScoreTable from "@/views/toefl/transcript/components/SubScoreTable";
import GoogleAd from "@/views/ad/GoogleAd";
import baseDomainPath from "@/common/baseDomainPath";

export default {
  props:["question_detail", "answers", "title", "lang","examInfo","isBrowse"],
  mixins: [Translate, role, toefl],
  components: {
    EditSpeaking,
    GradeLevelSelector,
    GradingSubScoreTable,
    SubScoreTable,
    GoogleAd
  },
  created(){
  },
  computed:{
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    hasAnswer() {
      return (this.question_detail.user_answers !== null && this.question_detail.user_answers.length > 0);
    },
    myScore(){
      return this.question_detail.score;
    },
    mySuggest() {
      return this.question_detail.suggest;
    },
    mySubScore() {
      return this.question_detail.answer_score_detail;
    },
    SPEAKING_QUESTION_LESSON_ID() {
      return 1;
    },
    questionTypeIsArticleAudio() {
      return this.question_detail.type === "article_audio";
    },
    questionTypeIsAudio() {
      return this.question_detail.type === "audio";
    },
    TEST_CATEGORY() {
      return "speaking_all";
    },
    baseDomainPath() {
      return baseDomainPath;
    }
  },
  data() {
    return {
      canEdit: false,
      showPassage: false,
      score_list: [1, 2, 3, 4],
      score: 0,
      suggest: "",
      speakingQuestionLesson: null,
      editQuestion: false,
      editPassage: false,
      editExplanation: false,
      editAudio: false
    };
  },
  watch: {},

  async mounted() {
    const { lesson } = await lessonsApi.getLesson(this.SPEAKING_QUESTION_LESSON_ID);
    this.speakingQuestionLesson = lesson;
  },

  methods: {
    autoSaveGrading() {
      if (!this.timer && this.isAdmin && !this.isBrowse) {
        this.timer = setTimeout(() => {
          this.saveTranscript("自動");
          clearInterval(this.timer);
          this.timer = null;
        }, 2000);
      }
    },
    getArr(arr) {
      arr.forEach(val => {
        let newVal = {
          id: val.user_exam_answer_id,
          score: val.score,
          suggest: val.suggest,
          answer_score_detail: {
            comment_type: this.TEST_CATEGORY,
            detail_score_comments: val.answer_score_detail.detail_score_comments
          }
        };
        this.updateAnswers.push(newVal);
      });
    },
    async saveTranscriptAndNotify() {
      await this.saveTranscript("手動");
      this.sendEmail(this.$route.query.user_exam_id);
    },
    async sendEmail(user_exam_id) {
      try {
        await this.$confirm("請問是否要發送通知郵件", "已儲存！", {
          confirmButtonText: "發送",
          cancelButtonText: "不發送"
        });
        await TOEFL.sendEmail(user_exam_id);
        this.$message({
          message: "發送成功！",
          type: "success"
        });
      } catch (e) {
        // do nothing
      }
    },
    async saveTranscript(text = "") {
      this.canEdit = false;
      this.updateAnswers = [];
      this.getArr([this.question_detail]);
      await TOEFL.updateTranscript(this.$route.query.user_exam_id, {
        user_exam_answers: this.updateAnswers
      });
      this.$notify({
        title: text + "儲存成功！",
        type: "success",
        position: "bottom-right",
        duration: "1000"
      });
    }
  }
};
</script>

<style scoped src="@/views/toefl/toeflResolve/style.css"></style>
<style scoped>
.overall-comments {
  margin-bottom: 8px;
}

.passage {
  background-color: #f2f3f5;
  padding: 0px 0px 20px 0px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.expandHeight {
  min-height: 100%;
}
.question {
  background-color: #f2f3f5;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.article {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.transcript {
  margin-bottom: 20px;
}

.tips {
  height: 112.5px;
  width: 100%;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-column-gap: 20px;
  margin-bottom: 20px;
}
.tips h5 {
  font-size: clamp(0.8rem, 1.6vw, 1.5rem);
  color: var(--themeColor);
}

.adImageWrapper {
  width: 100%;
  position: relative;
}
.adImageWrapper > .adImage {
  width: 100%;
}
.open {
  width: 40px;
  height: 70px;
  padding: 20px 0;
  background-color: #f2f3f5;
  font-size: 22px;
  top: 50%;
  transform: translateY(-35px);
  right: 0px;
  border-radius: 0 5px 5px 0;
}
.passage {
  background-color: #f2f3f5;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
}
.passage-box {
  padding-left: 0;
  padding-right: 40px;
  background: white;
}
.question-box {
  padding-left: 20px;
  padding-right: 0;
  background: white;
}
::v-deep .response.exp > div > div {
  background-color: #fdf6ec !important;
  color: #e6a23c !important;
  padding: 8px 16px !important;
}

.score {
  color: #ff8920;
  font-size: 24px;
  font-weight: bold;
}
</style>
