<template>
  <table class="table table-bordered subsocre-table">
    <thead>
      <tr>
        <th class="green-td">
          Skill
        </th>
        <th class="level-score green-td">
          Subscore
        </th>
        <th v-if="hasComment" class="green-td">
          Comments
        </th>
      </tr>
    </thead>
    <tbody>
      <tr :key="subScore.id" v-for="subScore in subScores">
        <td class="sub-skill-name">{{ subScore.title[lang] }}</td>
        <td :class="`score ${hasComment ? '' : 'stretch'}`">
          <div v-if="canScore">
            <div v-if="scored">
              <span v-if="subScore.score > 0">
                {{ scoreMapping[subScore.score] }}
              </span>
              <span v-else class="hasNotScore">-</span>
            </div>
            <div v-else class="unlockTag">
              {{ $t("message.unlockScored") }}
            </div>
          </div>
          <el-tooltip
            v-else
            class="item"
            effect="dark"
            :content="$t('toefl.balance.Get Comments for This Prompt')"
            placement="top"
          >
            <el-button type="warning" @click="$emit('unlock')">
              <i class="fas fa-lock"></i>
            </el-button>
          </el-tooltip>
        </td>
        <td v-if="hasComment" class="comment">
          <div v-html="subScore.comment" />
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import toeflApi from "@/apis/toefl.js";

export default {
  // speaking_all writing_independent writing_integrated
  props: ["testCategory", "value", "canScore", "scored", "testCategories", "suggest_type"],
  computed: {
    lang() {
      return this.$store.getters["user/langShortValue"];
    },
    hasComment() {
      return this.subScores.some(({ comment }) => comment !== "" && this.suggest_type==="detail");
    },
    scoreMapping() {
      return {
        1: "E",
        2: "D",
        3: "C",
        4: "B",
        5: "A"
      };
    }
  },
  data() {
    return {
      subScores: []
    };
  },
  async mounted() {
    let commentCategories = null;
    if (this.testCategories) {
      commentCategories = this.testCategories;
    } else {
      const { comment_categories: data } = await toeflApi.getSubScoreCategories(
        this.testCategory
      );
      commentCategories = data;
    }
    const findSubScore = categoryId =>
      (this.value || []).find(({ comment_category: { id } }) => id === categoryId);
    this.subScores = commentCategories.map(commentCategory => ({
      ...commentCategory,
      score: findSubScore(commentCategory.id)?.score || 0,
      comment: findSubScore(commentCategory.id)?.comment || ""
    }));
  },
  methods: {
    ridUseLessProp(subScores) {
      return subScores.map(subScore => ({
        order: subScore.order,
        comment_category_id: subScore.id,
        score: subScore.score,
        comment: subScore.comment
      }));
    },
    setSubScore(prop, id, value) {
      this.subScores = this.subScores.map(subScore => {
        if (subScore.id === id) {
          return { ...subScore, [prop]: value };
        }
        return subScore;
      });
      this.$emit("change", this.ridUseLessProp(this.subScores));
    }
  }
};
</script>

<style lang="scss" scoped>
.table .green-td {
  text-align: center;
  color: #fff;
  font-weight: bold;
  background-color: var(--themeColor);
}

.subsocre-table {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 20px;
}

tr > .sub-skill-name {
  text-align: left;
  font-weight: bolder;
  width: 200px;
}

tr > .score {
  color: #ff8920;
  font-size: 24px;
  font-weight: bold;
  width: 160px;
}

.hasNotScore {
  color: #000000;
  font-weight: normal;
}

tr > .stretch {
  width: auto;
}

tr > .comment {
  text-align: left;
}

.unlockTag {
  color: rgb(142, 199, 166);
  background-color: rgb(236, 246, 240);
  border: 1px solid rgb(217, 236, 225);
  border-radius: 20px;
  padding: 12px;
  line-height: 1.3;
  font-size: 14px;
  text-align: left;
  width: max-content;
  margin: 0px auto;
}
</style>
