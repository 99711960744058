<template>
  <span v-if="type">
    <el-button type="success" size="mini" @click="openEdit">
      <i class="fas fa-edit"></i>
      <b>Edit</b>
    </el-button>
    <el-dialog :title="`${type}-Edit`" :visible.sync="edit" width="80%">
      <template v-if="workQuestionDetail">
        <Question
          v-if="type === 'question'"
          :newQuestionDetail="workQuestionDetail"
          type="listening"
        />
        <template v-if="type === 'explanation'">
          <div>
            <el-input
              type="textarea"
              :rows="10"
              v-model="workQuestionDetail.exp"
            ></el-input>
          </div>
        </template>
        <template v-if="type === 'passage'">
          <el-form ref="form" label-width="80px">
            <el-form-item label="Passage">
              <el-input
                type="textarea"
                :rows="20"
                v-model="workQuestionDetail.chapter_content.text"
              ></el-input>
            </el-form-item>
          </el-form>
        </template>
        <template v-if="type === 'audio'">
          <el-upload
            class="upload-demo"
            drag
            action="https://jsonplaceholder.typicode.com/posts/"
            multiple
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
        </template>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeEdit">Cancel</el-button>
          <el-button type="primary" @click="saveQuestion">
            Save
          </el-button>
        </span>
      </template>
    </el-dialog>
  </span>
</template>

<script>
import _ from "lodash";
import TOEFL from "@/apis/toefl";
import Question from "@/views/toefl/toeflResolve/Edit/Question";

export default {
  components: {
    Question
  },

  mixins: [],

  props: ["question_detail", "type"],
  data() {
    return {
      selectedType: "",
      workQuestionDetail: null,
      edit: false
    };
  },
  computed: {
    optionsArr() {
      let wordArr = [];
      for (var i = 65; i < 90; i++) {
        wordArr.push(String.fromCharCode(i));
      }
      return wordArr;
    }
  },
  methods: {
    openEdit() {
      this.edit = true;
      this.workQuestionDetail = _.cloneDeep(this.question_detail);
    },
    closeEdit() {
      this.edit = false;
      this.workQuestionDetail = null;
    },
    async saveQuestion() {
      const id = this.workQuestionDetail.id;
      let tags = [];
      let answers = [];
      switch (this.type) {
        case "question":
          this.workQuestionDetail.options.map(option => {
            TOEFL.editOption(option.id, {
              title: `${this.optionsArr[option.number - 1]}. ${option.title}`
            });
          });
          this.workQuestionDetail.tags.map(tag => {
            tags.push(tag.id);
          });
          this.workQuestionDetail.answers.map(tag => {
            answers.push(tag.id);
          });

          await Promise.all([
            TOEFL.updateTags(id, {
              tag_ids: tags
            }),
            TOEFL.editQuestion(id, {
              question: this.workQuestionDetail.question
            }),
            TOEFL.editAnswer(this.workQuestionDetail.answers[0].id, {
              answers: this.workQuestionDetail.answers[0].answers,
              letter_answers: this.workQuestionDetail.answers[0].letter_answers
            })
          ]).then(values => {
            this.edit = false;
            this.$message({
              message: this.$t("message.update_success"),
              type: "success",
              onClose: this.$router.go(0)
            });
          });
          break;
        case "passage":
          await TOEFL.editChapter(this.workQuestionDetail.toefl_chapter_id, {
            content: this.workQuestionDetail.chapter_content
          });
          this.edit = false;
          this.$message({
            message: this.$t("message.update_success"),
            type: "success",
            onClose: this.$router.go(0)
          });
          break;
        case "explanation":
          await TOEFL.editQuestion(this.workQuestionDetail.id, {
            exp: this.workQuestionDetail.exp
          });
          this.edit = false;
          this.$message({
            message: this.$t("message.update_success"),
            type: "success",
            onClose: this.$router.go(0)
          });
          break;
        default:
          return null;
      }
    }
  }
};
</script>

<style scoped>
.option {
  display: flex;
}
.removeBtn {
  cursor: pointer;
  margin-left: 10px;
}
::v-deep .el-input-group__prepend {
  width: 40px;
}
.add-button {
  font-size: 20px;
  cursor: pointer;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px dashed rgb(192, 204, 218);
  border-radius: 6px;
}
.add-button:hover {
  border-color: var(--themeColor);
  color: var(--themeColor);
}
.option {
  margin-bottom: 20px;
}
.q_tags {
  margin: 10px 0 20px 0;
}

::v-deep .q_tags > .el-tag {
  margin-right: 8px;
  margin-bottom: 8px;
}
</style>
