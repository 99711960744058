<template>
  <div class="cover">
    <div class="box-top" :style="changeTop">
      <div
        :class="{
          'cover-child': true,
          'passage-box': true
        }"
      >
        <div class="passage">
          <div class="resolve_content">
            <div class="title">{{ $t("pageTitle.Directions") }}</div>
            <h6 class="article" v-html="question_detail.question"></h6>
            <hr />
          </div>
          <div class="resolve_content">
            <div class="title">{{ $t("pageTitle.Question") }}</div>
            <h6 class="article">
              <div class="q_tags">
                <el-tag :key="tag.id" v-for="tag in question_detail.tags">
                  {{ tag.name[$store.getters["user/langShortValue"]] }}
                </el-tag>
              </div>
              <div v-html="question_detail.content.article" />
            </h6>
            <hr />
          </div>
          <div v-if="question_detail.type == 'integrated'">
            <div>
              <div class="resolve_content">
                <div class="title">{{ $t("pageTitle.Audio") }}</div>
              </div>
              <audio :src="question_detail.content.audio" controls></audio>
              <h6>
                <div
                  class="article"
                  v-html="question_detail.content.audio_text"
                ></div>
              </h6>
            </div>
            <hr />
          </div>
          <div class="open" @click="showPassage = !showPassage">
            <div class="text" v-show="!showPassage">
              <i class="fas fa-chevron-circle-right"></i>
            </div>
            <div class="text" v-show="showPassage">
              <i class="fas fa-chevron-circle-left"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="change-box" :style="changeCenter" v-show="boxType === 0">
        <el-button
          type="primary"
          size="small"
          style="width:100%"
          @click="boxType = 1"
        >
          <i class="fas fa-arrow-down"></i>
        </el-button>
        <el-button
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 2"
        >
          <i class="fas fa-arrow-up"></i>
        </el-button>
      </div>
      <div class="change-box" :style="changeCenter" v-show="boxType === 1">
        <el-button
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 3"
        >
          <i class="fas fa-arrow-down"></i>
        </el-button>
        <el-button
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 0"
        >
          <i class="fas fa-arrow-up"></i>
        </el-button>
      </div>
      <div class="change-box" :style="changeCenter" v-show="boxType === 2">
        <el-button
          v-show="boxType === 2"
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 0"
        >
          <i class="fas fa-arrow-down"></i>
        </el-button>
        <el-button
          v-show="boxType === 2"
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 4"
        >
          <i class="fas fa-arrow-up"></i>
        </el-button>
      </div>
      <div class="change-box" :style="changeCenter" v-show="boxType === 3">
        <el-button
          type="info"
          size="small"
          style="width:100%;margin:0"
          disabled
        >
          <i class="fas fa-arrow-down"></i>
        </el-button>
        <el-button
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 1"
        >
          <i class="fas fa-arrow-up"></i>
        </el-button>
      </div>
      <div class="change-box" :style="changeCenter" v-show="boxType === 4">
        <el-button
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 2"
        >
          <i class="fas fa-arrow-down"></i>
        </el-button>
        <el-button
          type="info"
          size="small"
          style="width:100%;margin:0"
          disabled
        >
          <i class="fas fa-arrow-up"></i>
        </el-button>
      </div>
    </div>
    <div class="box-bottom" :style="changeBottom">
      <div v-show="!showPassage" class="cover-child question-box">
        <div class="question" style="opacity: 1;">
          <Breadcrumb :customBreadcrumbList="[{ name: 'ToeflTestResults' }]">
            <template slot="restPage">
              <el-breadcrumb-item>
                <router-link :to="`/result?id=${$route.query.user_exam_id}`">
                  Test Result
                </router-link>
              </el-breadcrumb-item>
              <el-breadcrumb-item>
                {{ title }}
              </el-breadcrumb-item>
            </template>
          </Breadcrumb>
          <div class="speaking_resolve">
            <template v-if="!isBrowse">
              <div class="resolve_content">
                <div class="title">{{ $t("pageTitle.My Answer") }}</div>
                <div class="exp">
                  <div style="margin-bottom:20px">
                    <div v-if="isAdmin">
                      <div v-if="examInfo.can_score === 1">
                        <b style="line-height:41px">
                          Score:
                        </b>
                        <el-select
                          v-model="question_detail.score"
                          placeholder="Score"
                          :disabled="false"
                        >
                          <el-option label="請選擇分數" :value="0">
                            請選擇分數
                          </el-option>
                          <el-option
                            v-for="item in score_list"
                            :key="item"
                            :label="item"
                            :value="item"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div v-else>
                      <div v-if="examInfo.can_score === 1">
                        <b
                          style="line-height:41px"
                          v-if="examInfo.is_scored === 1"
                        >
                          Score:
                          <span class="text-warning" style="font-size:18px">
                            {{ question_detail.score }}</span
                          >
                        </b>
                        <div v-else>
                          <el-button
                            type="success"
                            style="width:100%"
                            disabled
                            plain
                            round
                          >
                            {{ $t("message.unlockScored") }}
                          </el-button>
                        </div>
                      </div>
                      <el-button
                        v-else
                        type="warning"
                        @click="$parent.getAlert()"
                        style="width:100%"
                      >
                        {{ $t("toefl.balance.Show Score") }}
                      </el-button>
                    </div>
                  </div>
                  <div
                    v-if="
                      question_detail.user_answers &&
                        question_detail.user_answers[0]
                    "
                    v-html="setText(question_detail.user_answers[0])"
                    style="margin-bottom:10px"
                  ></div>
                  <div style="margin-top:40px">
                    <div v-if="isAdmin">
                      <div class="ckeditor" v-if="examInfo.can_suggest === 1">
                        <div v-if="examInfo.suggest_type === 'outline'">
                          <el-divider content-position="left">
                            <b>{{ $t("GradingSamples.Simple Grading") }}</b>
                          </el-divider>
                          <ckeditor v-model="question_detail.suggest"></ckeditor>
                        </div>
                        <div v-if="examInfo.suggest_type === 'detail'">
                          <el-divider content-position="left">
                            <b class="vip">
                              <el-tag type="warning" effect="plain">
                                <i class="fas fa-chess-queen"></i>
                                VIP
                              </el-tag></b
                            ><b>{{
                              $t("GradingSamples.VIP Grading")
                            }}</b></el-divider
                          >
                          <ckeditor v-model="question_detail.suggest"></ckeditor>
                        </div>
                      </div>
                      <el-button
                        @click="saveTranscriptAndNotify"
                        size="mini"
                        type="success"
                      >
                        <i class="fa fa-save"></i> 保存並寄送通知
                      </el-button>
                    </div>
                    <div v-else>
                      <div v-if="examInfo.can_suggest === 1">
                        <div
                          v-if="examInfo.is_scored === 1"
                          style="word-break: normal !important;"
                        >
                          <div v-if="examInfo.suggest_type === 'outline'">
                            <el-divider content-position="left">
                              <b>{{ $t("GradingSamples.Simple Grading") }}</b>
                            </el-divider>
                            <div
                              class="table"
                              v-html="question_detail.suggest"
                            ></div>
                          </div>
                          <div v-if="examInfo.suggest_type === 'detail'">
                            <el-divider content-position="left">
                              <b class="vip">
                                <el-tag type="warning" effect="plain">
                                  <i class="fas fa-chess-queen"></i>
                                  VIP
                                </el-tag></b
                              ><b>{{
                                $t("GradingSamples.VIP Grading")
                              }}</b></el-divider
                            >
                            <div
                              class="table"
                              v-html="question_detail.suggest"
                            ></div>
                          </div>
                        </div>
                        <div v-else>
                          <el-button
                            v-if="examInfo.suggest_type === 'outline'"
                            style="width:100%;"
                            type="success"
                            disabled
                            plain
                            round
                          >
                            {{ $t("message.unlockBasic") }}
                          </el-button>
                          <el-button
                            v-if="examInfo.suggest_type === 'detail'"
                            style="width:100%"
                            type="success"
                            disabled
                            plain
                            round
                          >
                            {{ $t("message.unlockDetail") }}
                          </el-button>
                        </div>
                      </div>
                      <div v-else>
                        <el-divider content-position="left">{{ $t("toefl.balance.Grader Comments") }}</el-divider>
                        <el-button type="warning" @click="$parent.getAlert()" style="width:100%">
                          {{ $t("toefl.balance.Get Comments for This Prompt") }}
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </template>
            <!-- <template
              v-if="
                question_detail.chapter_order === 1 && writingQuestionLesson
              "
            >
              <div class="tips">
                <div class="adImageWrapper">
                  <img class="adImage" :src="writingQuestionLesson.cover" alt="" />
                </div>
                <h5>
                  <a
                    :href="`${baseDomainPath}lessons?v=${WRITING_QUESTION_LESSON_ID}`"
                    target="_blank"
                  >
                    {{ $t("message.customLessonWrite1AdTitle") }}
                  </a>
                </h5>
              </div>
            </template> -->
            <div class="resolve_content">
              <div class="title">{{ $t("pageTitle.Sample Response") }}</div>
              <div class="exp">
                <span
                  v-if="lang === 'ZH-TW' || lang === 'EN-US'"
                  v-html="traditionalized(replaceLine(question_detail.exp), lang)"
                >
                </span>
                <span v-else v-html="replaceLine(question_detail.exp)"></span>
              </div>
            </div>
            <GoogleAd />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import Translate from "@/mixins/translate.js";
import role from "@/mixins/role.js";
import toefl from "@/mixins/toefl.js";
import TOEFL from "@/apis/toefl";
import lessonsApi from "@/apis/lessons";
import GoogleAd from "@/views/ad/GoogleAd";
import baseDomainPath from "@/common/baseDomainPath";

export default {
  props:["question_detail", "answers", "title", "lang", "examInfo", "isBrowse"],
  components: {
    GoogleAd
  },
  mixins: [Translate, role, toefl],
  created(){
  },
  computed:{
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    changeTop() {
      let str = "";
      if (this.boxType === 0) {
        str = "height: 47%";
      }
      if (this.boxType === 1) {
        str = "height: 69%";
      }
      if (this.boxType === 2) {
        str = "height: 25%";
      }
      if (this.boxType === 3) {
        str = "height: 94%";
      }
      if (this.boxType === 4) {
        str = "display:none";
      }
      return str;
    },
    changeBottom() {
      let str = "";
      if (this.boxType === 0) {
        str = "height: 47%";
      }
      if (this.boxType === 1) {
        str = "height: 25%";
      }
      if (this.boxType === 2) {
        str = "height: 69%";
      }
      if (this.boxType === 3) {
        str = "display:none";
      }
      if (this.boxType === 4) {
        str = "height: 94%";
      }
      return str;
    },
    changeCenter() {
      let str = "height: 6%";
      return str;
    },
    WRITING_QUESTION_LESSON_ID() {
      return 2;
    },
    baseDomainPath() {
      return baseDomainPath;
    }
  },
  data() {
    return {
      boxType: 0,
      canEdit: false,
      showPassage: false,
      score_list: [1, 2, 3, 4, 5],
      score: 0,
      suggest: "",
      writingQuestionLesson: null,
      updateAnswers: [],
      basicModel: `<h5><strong>TOEFL Writing Rubric Score Level: <span style="color:#f39c12">0/5</span></strong></h5>
<h5><strong>整合式:</strong></h5>
<table border="1" cellpadding="1" cellspacing="1" style="width:100%">
	<tbody>
		<tr>
			<td style="text-align:center; vertical-align:middle; width:350px"><strong>An essay at this level may reveal one or more of the following weaknesses:</strong></td>
			<td style="text-align:center; vertical-align:middle"><strong>Teacher Commentary</strong></td>
		</tr>
		<tr>
			<td>
			<ul>
				<li>Selects important information from the lecture</li>
			</ul>
			</td>
			<td colspan="1" rowspan="4" style="text-align:left; vertical-align:top">
			<p>&nbsp;</p>
			</td>
		</tr>
		<tr>
			<td>
			<ul>
				<li>Coherently and accurately presents the relationship</li>
			</ul>
			</td>
		</tr>
		<tr>
			<td>
			<ul>
				<li>Well organized</li>
			</ul>
			</td>
		</tr>
		<tr>
			<td>
			<ul>
				<li>Errors do not result in inaccurate or imprecise presentation of information</li>
			</ul>
			</td>
		</tr>
	</tbody>
</table>

<h5><strong>獨立式:</strong></h5>
<table border="1" cellpadding="1" cellspacing="1" style="width:100%">
	<tbody>
		<tr>
			<td style="text-align:center; vertical-align:middle; width:350px"><strong>An essay at this level may reveal one or more of the following weaknesses:</strong></td>
			<td style="text-align:center; vertical-align:middle"><strong>Teacher Commentary</strong></td>
		</tr>
		<tr>
			<td>
			<ul>
				<li>Addressing the topic and task</li>
			</ul>
			</td>
			<td colspan="1" rowspan="4" style="text-align:left; vertical-align:top">
			<p>&nbsp;</p>
			</td>
		</tr>
		<tr>
			<td>
			<ul>
				<li>Organization, development, and details</li>
			</ul>
			</td>
		</tr>
		<tr>
			<td>
			<ul>
				<li>Unity, progression, and coherence</li>
			</ul>
			</td>
		</tr>
		<tr>
			<td>
			<ul>
				<li>Language use, word choice, and grammar</li>
			</ul>
			</td>
		</tr>
	</tbody>
</table>
`,
      detailModel: `<h5><strong>TOEFL Writing Rubric Score Level: <span style="color:#f39c12">1/5</span></strong></h5>

<h5><strong>整合式:</strong></h5>
<table border="1" cellpadding="1" cellspacing="1" style="width:100%">
	<tbody>
		<tr>
			<td style="text-align:center; vertical-align:middle; width:350px"><strong>An essay at this level may reveal one or more of the following weaknesses:</strong></td>
			<td style="text-align:center; vertical-align:middle; width:100px">
			<p><strong>Score</strong></p>

			<p><strong>(1~5)</strong></p>
			</td>
			<td style="text-align:center; vertical-align:middle"><strong>Teacher Commentary</strong></td>
		</tr>
		<tr>
			<td>
			<ul>
				<li>Selects important information from the lecture</li>
			</ul>
			</td>
			<td style="text-align:center; vertical-align:middle"><span style="color:#f39c12"><strong>1</strong></span></td>
			<td>&nbsp;</td>
		</tr>
		<tr>
			<td>
			<ul>
				<li>Coherently and accurately presents the relationship</li>
			</ul>
			</td>
			<td style="text-align:center; vertical-align:middle"><span style="color:#f39c12"><strong>1</strong></span></td>
			<td>&nbsp;</td>
		</tr>
		<tr>
			<td>
			<ul>
				<li>Well organized</li>
			</ul>
			</td>
			<td style="text-align:center; vertical-align:middle"><strong><span style="color:#f39c12">1</span></strong></td>
			<td>&nbsp;</td>
		</tr>
		<tr>
			<td>
			<ul>
				<li>Errors do not result in inaccurate or imprecise presentation of information</li>
			</ul>
			</td>
			<td style="text-align:center; vertical-align:middle"><strong><span style="color:#f39c12">1</span></strong></td>
			<td>&nbsp;</td>
		</tr>
	</tbody>
</table>

<h5><strong>獨立式:</strong></h5>
<table border="1" cellpadding="1" cellspacing="1" style="width:100%">
	<tbody>
		<tr>
			<td style="text-align:center; vertical-align:middle; width:350px"><strong>An essay at this level may reveal one or more of the following weaknesses:</strong></td>
			<td style="text-align:center; vertical-align:middle; width:100px">
			<p><strong>Score</strong></p>

			<p><strong>(1~5)</strong></p>
			</td>
			<td style="text-align:center; vertical-align:middle"><strong>Teacher Commentary</strong></td>
		</tr>
		<tr>
			<td>
			<ul>
				<li>Addressing the topic and task</li>
			</ul>
			</td>
			<td style="text-align:center; vertical-align:middle"><span style="color:#f39c12"><strong>1</strong></span></td>
			<td>&nbsp;</td>
		</tr>
		<tr>
			<td>
			<ul>
				<li>Organization, development, and details</li>
			</ul>
			</td>
			<td style="text-align:center; vertical-align:middle"><span style="color:#f39c12"><strong>1</strong></span></td>
			<td>&nbsp;</td>
		</tr>
		<tr>
			<td>
			<ul>
				<li>Unity, progression, and coherence</li>
			</ul>
			</td>
			<td style="text-align:center; vertical-align:middle"><strong><span style="color:#f39c12">1</span></strong></td>
			<td>&nbsp;</td>
		</tr>
		<tr>
			<td>
			<ul>
				<li>Language use, word choice, and grammar</li>
			</ul>
			</td>
			<td style="text-align:center; vertical-align:middle"><strong><span style="color:#f39c12">1</span></strong></td>
			<td>&nbsp;</td>
		</tr>
	</tbody>
</table>

<hr />
<p><strong>Detailed Corrections and Feedback:</strong></p>

<p>(You can copy the student's response into a Google Doc, make comments and edits on it, set the document to public, and paste the document link here. <a href="https://docs.google.com/document/d/1c-h8hEE87ttjm1bHlwRWqPC6Hho9JGwXIVpBw5ndhdU/edit?usp=sharing">Please click here to see your response’s corrections and feedback on Google Document</a>)</p>
`

    };
  },
  watch: {
    examInfo(){
      this.setSuggest();
    },
    question_detail: {
      handler() {
        if (!this.timer && this.isAdmin) {
          this.timer = setTimeout(() => {
            this.saveTranscript("自動");
            clearInterval(this.timer);
            this.timer = null;
          }, 2000);
        }
      },
      deep: true
    }
  },

  async mounted() {
    this.setSuggest();
    const { lesson } = await lessonsApi.getLesson(this.WRITING_QUESTION_LESSON_ID);
    this.writingQuestionLesson = lesson;
  },

  methods: {
    setText(text) {
      return text.replace(/\n/g, "<br />");
    },
    setSuggest() {
      if (this.question_detail.suggest === "" || this.question_detail.suggest === null) {
        if (this.examInfo.suggest_type === "outline") {
          this.question_detail.suggest = this.basicModel;
        } else if (this.examInfo.suggest_type === "detail") {
          this.question_detail.suggest = this.detailModel;
        }
      }
    },
    getArr(arr) {
      arr.forEach(val => {
        let newVal = {
          id: val.user_exam_answer_id,
          score: val.score,
          suggest: val.suggest
        };
        this.updateAnswers.push(newVal);
      });
    },
    async saveTranscriptAndNotify() {
      await this.saveTranscript("手動");
      this.sendEmail(this.$route.query.user_exam_id);
    },
    async sendEmail(user_exam_id) {
      try {
        await this.$confirm("請問是否要發送通知郵件", "已儲存！", {
          confirmButtonText: "發送",
          cancelButtonText: "不發送"
        });
        await TOEFL.sendEmail(user_exam_id);
        this.$message({
          message: "發送成功！",
          type: "success"
        });
      } catch (e) {
        // do nothing
      }
    },
    async saveTranscript(text = "") {
      this.canEdit = false;
      this.updateAnswers = [];
      this.getArr([this.question_detail]);
      await TOEFL.updateTranscript(this.$route.query.user_exam_id, {
        user_exam_answers: this.updateAnswers
      });
      this.$notify({
        title: text + "儲存成功！",
        type: "success",
        position: "bottom-right",
        duration: "1000"
      });
    }
  }
};
</script>

<style scoped src="@/views/toefl/toeflResolve/style.css"></style>
<style scoped>
.cover {
  margin: 0;
}
.passage,
.question {
  height: 100%;
  overflow: scroll;
  background-color: #f2f3f5;
  padding: 15px;
  border-radius: 5px;
}
.passage-box,
.question-box {
  padding-left: 0;
  padding-right: 0;
  background: white;
}

.open {
  display: none;
  width: 40px;
  height: 70px;
  padding: 20px 0;
  background-color: #f2f3f5;
  font-size: 22px;
  top: 50%;
  transform: translateY(-35px);
  right: 0px;
  border-radius: 0 5px 5px 0;
}
.article {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.vip {
  color: #f0ad4e;
}
.vip >>> .el-tag {
  margin-right: 8px;
  height: 20px;
  line-height: 18px;
  padding: 0 4px;
}

.ckeditor {
  margin-bottom: 20px;
}

.tips .img img {
  height: 120px;
  width: 150px;
}
.tips {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.tips h5 {
  height: 120px;
  color: var(--themeColor);
}

.adImageWrapper {
  width: 50%;
  padding-right: 20px;
  position: relative;
}
.adImageWrapper > .adImage {
  width: 100%;
}
</style>
